<template>
  <div>
    <s-dialog :open="true" size="sm" :to="`stds-dialog-delete-discount`">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[39.2rem]">
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-xl text-on-surface-elevation-1">
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_list_suspend_pop_msg1') }}
            </p>
            <p class="mt-4 text-lg leading-lg text-on-surface-elevation-3 tracking-[-0.45px]">
              <safe-html
                :html="$t('studio.prj_prod.this_prod.prod_mgmt_discount_list_suspend_pop_msg2')"
              />
            </p>
            <div class="mt-8">
              <input-text
                size="lg"
                variant="outline"
                :placeholder="SUSPEND_TEXT"
                name="confirmInputText"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
          <s-dialog-footer class="w-full px-0">
            <s-button variant="outline" @click="onClose">
              {{
                $t('studio.common.popup_case_cancel_btn')
              }}
            </s-button>
            <s-button
              variant="red"
              :disabled="values.confirmInputText.toUpperCase() !== SUSPEND_TEXT"
              @click="onSubmit"
            >
              {{ $t('studio.prj_prod.this_prod.prod_mgmt_discount_list_suspend_pop_withdraw_btn') }}
            </s-button>
          </s-dialog-footer>
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-delete-discount`" />
  </div>
</template>
<script setup lang="ts">
import { useForm } from 'vee-validate';

import SafeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';

const SUSPEND_TEXT = 'SUSPEND';

const { handleSubmit, values } = useForm({
  initialValues: {
    confirmInputText: ''
  }
});

const emits = defineEmits<{
  close: [v: boolean];
}>();

const onSubmit = handleSubmit(() => {
  emits('close', true);
});

const onClose = () => {
  emits('close', false);
};
</script>
