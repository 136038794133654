export const DISCOUNT_TYPE = {
  ALL: 'ALL',
  APPLYING: 'APPLYING',
  END: 'END'
};

export const COLLECTION_DISCOUNT_TYPE = {
  PERMANENT: '상시 할인',
  PERIOD: '기간 할인',
  UNUSED: '미사용'
};
